.loadingSpinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid rgb(52, 58, 64);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
