.allBeerPrices h1 {
  font-size: 48px;
  font-family: "Bebas Neue", cursive;
  text-align: center;
  padding-top: 15px;
}

.allBeerPrices p {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}

.filterHeading {
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin-bottom: 2px;
}

.beerLink {
  text-decoration: none;
}

.beerLink:hover {
  text-decoration: underline;
}

.noSelectedBeerMessage {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin: 50px 0px 120px 0px;
}

.originalPrice {
  text-decoration: line-through;
  color: #868686;
}