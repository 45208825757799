.cardContainer {
  display: flex;
  flex-direction: row;
  background-color: #3a4047;
  border-radius: 10px;
  overflow: hidden;
  transition: background-color 0.3s;
  max-width: 100%;
  font-family: "Roboto", sans-serif;
  margin: 10px 0;
}

.cardContainer:hover {
  background-color: #de9d12;
}

.cardTitleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
  border-right: 3px solid #fff;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 3;
  padding: 10px 20px;
}

.cardTitle {
  color: #fff;
  font-family: "Bebas Neue", cursive;
  margin: 0;
}

.cardImage {
  height: 50px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardImage img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 768px) {
  .cardContainer {
    flex-direction: column;
  }
  .cardTitleBox {
    border-right: none;
    border-bottom: 3px solid #fff;
  }
}

.cardDescription {
  color: #fff;
  margin: 0;
  text-align: center;
}

.clickable {
  cursor: pointer;
}
